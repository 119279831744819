import { useRoutes } from 'react-router-dom';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import "./css/sweetAlert.css"

import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';

// import routes from './router';
import Router from './router/index';

const App = () => {

  // const content = useRoutes(routes);

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {/* {content} */}
        <Router />
      </LocalizationProvider>
    </ThemeProvider>
  );
}
export default App;
