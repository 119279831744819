import enMessages from '../messages/en.json';
import idMessages from '../messages/id.json'
import { IntlProvider } from 'react-intl';
import { useLang } from './i18n';

const allMessages = {
  en: enMessages,
  id: idMessages
};

export function I18nProvider({children}) {
  const locale = useLang();
  const messages = allMessages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
}


