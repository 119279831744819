import * as ACTION from '../../constants/certificate';
import * as ACTION_ALERT from '../../constants/fadeSnackbar';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { call, put, takeLatest } from 'redux-saga/effects';
import * as API from '../../api/Certificate';

const initialState = {};

export const reducer = persistReducer(
  { storage, key: 'X-Certificate' },
  (state: any = initialState, action: any) => {
    switch (action.type) {
      case ACTION.GET_CERTIFCATE_REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case ACTION.GET_CERTIFCATE_FAILED:
        return {
          isLoading: false
        };
      case ACTION.GET_CERTIFCATE_SUCCESS:
        return {
          ...state,
          ...action.payload,
          isLoading: false
        };
      case ACTION.CLEAR_CERTIFICATE:
        return {
          isLoading: false
        };
      default:
        return state;
    }
  }
);

export function* saga() {
  yield takeLatest(
    ACTION.GET_CERTIFCATE_REQUEST,
    function* certificateRequest(action: any): any {
      try {
        const data = yield call(API.getCertificate, action.payload);
        yield put({
          type: ACTION.GET_CERTIFCATE_SUCCESS,
          payload: data
        });
      } catch (error) {
        yield put({
          type: ACTION.GET_CERTIFCATE_FAILED,
          payload: error
        });
        yield put({
          type: ACTION_ALERT.SHOW_POPUP,
          payload:
            error?.response?.data?.errors !== undefined
              ? error?.response?.data?.errors[0].level +
                ' ' +
                error?.response?.data?.errors[0].objectName +
                ' ' +
                error?.response?.data?.errors[0].message
              : error?.response?.data?.message !== undefined
              ? error?.response?.data?.message
              : error?.message !== undefined
              ? error?.message
              : error
        });
      }
    }
  );
}
