import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';

function RequireAuth(props) {
  const { accessToken, children } = props;
  let location = useLocation();

  if (!accessToken) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <Outlet />;
}

const mapStateToProps = (state: any) => {
  return {
    accessToken: state.user.accessToken
  };
};

export default connect(mapStateToProps, {})(RequireAuth);
