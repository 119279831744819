import * as ACTION_ALERT from '../../constants/fadeSnackbar';
import * as ACTION_USER from '../../constants/user';
import * as ACTION from '../../constants/quiz';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { call, put, takeEvery, takeLatest, select } from 'redux-saga/effects';
import * as API from '../../api/Quiz';

import AnswerConfirmation from '../../components/SweetAlert/AnswerConfirmation';
import EmptyAnswerConfirmation from 'src/components/SweetAlert/EmptyAnswerConfirmation';
import FinishQuiz from 'src/components/SweetAlert/FinishQuiz';
import Timesup from 'src/components/SweetAlert/Timesup';

const getBlockId = (state) => state.quiz.quizBlock.id;

const getQuizFlow = (state) => state.quiz.quizFlow;

const getImmutableResponse = (state) => state.user.quiz.immutableResponse;

const getIsLastQuestionBlock = (state) =>
  state.quiz.quizBlock.next !== null &&
  state.quiz.quizBlock.questions.length - 1 === state.quiz.questionIndex;

const getIsFirstQuestionBlock = (state) =>
  state.quiz.quizBlock.prev !== null && state.quiz.questionIndex === 0;

const getIsLastQuestionQuiz = (state) =>
  state.quiz.quizBlock.next === null &&
  state.quiz.quizBlock.questions.length - 1 === state.quiz.questionIndex;

const initialState = {};

export const reducer = persistReducer(
  { storage, key: 'X-Quiz' },
  (state: any = initialState, action: any) => {
    switch (action.type) {
      case ACTION.EMPTY_DATA_QUIZ:
        return {};
      case ACTION.START_QUIZ_REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case ACTION.START_QUIZ_FAILED:
        return {
          ...state,
          isLoading: false
        };
      case ACTION.START_QUIZ_SUCCESS:
        const { data } = action.payload;
        return {
          ...state,
          ...data,
          quizBlock: {
            ...data.quizBlock,
            questionIndex: 0
          },
          isLoading: false
        };
      case ACTION.NEW_FINISH_QUIZ_REQUEST:
        return {
          ...state,
          isLoading: true,
          results: {
            ...state.results
          }
        };
      case ACTION.NEW_FINISH_QUIZ_FAILED:
        return {
          ...state,
          isLoading: false,
          results: {
            ...state.results
          }
        };
      case ACTION.NEW_FINISH_QUIZ_SUCCESS:
        return {
          ...state,
          isLoading: false,
          results: {
            ...action.payload,
            finished: true
          }
        };
      case ACTION.GET_RESULT_REQUEST:
        return {
          ...state,
          results: {
            ...state.results,
            isLoading: true
          }
        };
      case ACTION.GET_RESULT_FAILED:
        return {
          ...state,
          results: {
            ...state.results,
            isLoading: false
          }
        };
      case ACTION.GET_RESULT_SUCCESS:
        return {
          ...state,
          results: {
            ...action.payload,
            finished: true,
            isLoading: false
          }
        };
      case ACTION.CLEANUP_RESULT:
        return {
          ...state,
          results: {
            isLoading: false
          }
        };
      case ACTION.NEXT_BLOCK_REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case ACTION.NEXT_BLOCK_FAILED:
        return {
          ...state,
          isLoading: false
        };
      case ACTION.NEXT_BLOCK_SUCCESS:
        return {
          ...state,
          finishTime: action.payload.headers['quiz-end-time'],
          quizBlock: { ...action.payload.data },
          questionIndex: action?.payload?.data?.questions?.findIndex(
            (e) => e.answered === false
          ),
          isLoading: false
        };
      case ACTION.PREV_BLOCK_REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case ACTION.PREV_BLOCK_FAILED:
        return {
          ...state,
          isLoading: false
        };
      case ACTION.PREV_BLOCK_SUCCESS:
        return {
          ...state,
          finishTime: action.payload.headers['quiz-end-time'],
          quizBlock: { ...action.payload.data },
          questionIndex: action?.payload?.data?.questions?.findIndex(
            (e) => e.answered === false
          ),
          isLoading: false
        };
      case ACTION.CURRENT_BLOCK_REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case ACTION.CURRENT_BLOCK_FAILED:
        return {
          ...state,
          isLoading: false
        };
      case ACTION.CURRENT_BLOCK_SUCCESS:
        return {
          ...state,
          finishTime: action.payload.headers['quiz-end-time'],
          quizBlock: { ...action.payload.data },
          questionIndex: action?.payload?.data?.questions?.findIndex(
            (e) => e.answered === false
          ),
          isLoading: false
        };
      case ACTION.ANSWER_QUESTION_REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case ACTION.ANSWER_QUESTION_FAILED:
        return {
          ...state,
          isLoading: false
        };
      case ACTION.ANSWER_QUESTION_SUCCESS:
        return {
          ...state,
          quizBlock: {
            ...state.quizBlock,
            questions: state.quizBlock.questions.map((item) => {
              if (item.responseId === action.payload.responseId) {
                if (item.type.id === 1 || item.type.id === 2) {
                  return {
                    ...item,
                    options: {
                      ...item.options,
                      answers: item.options.answers.map((answer) => {
                        return action.payload.mcAnswer.includes(answer.text)
                          ? {
                              ...answer,
                              selected: true
                            }
                          : {
                              ...answer,
                              selected: false
                            };
                      })
                    },
                    answered: action.payload.answer === '' ? false : true
                  };
                } else {
                  return {
                    ...item,
                    textResponse: action.payload.textAnswer,
                    answered: action.payload.answer === '' ? false : true
                  };
                }
              } else {
                return item;
              }
            })
          },
          isLoading: false
        };
      case ACTION.NEW_NEXT_QUESTION_REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case ACTION.NEW_NEXT_QUESTION_FAILED:
        return {
          ...state,
          isLoading: false
        };
      case ACTION.NEW_NEXT_QUESTION_SUCCESS:
        return {
          ...state,
          isLoading: false,
          questionIndex: state.questionIndex + 1
        };
      case ACTION.NEW_SKIP_QUESTION_REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case ACTION.NEW_SKIP_QUESTION_FAILED:
        return {
          ...state,
          isLoading: false
        };
      case ACTION.NEW_SKIP_QUESTION_SUCCESS:
        return {
          ...state,
          isLoading: false,
          questionIndex: state.questionIndex + 1
        };
      case ACTION.NEW_PREV_QUESTION_REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case ACTION.NEW_PREV_QUESTION_FAILED:
        return {
          ...state,
          isLoading: false
        };
      case ACTION.NEW_PREV_QUESTION_SUCCESS:
        return {
          ...state,
          isLoading: false,
          questionIndex: state.questionIndex - 1
        };
      case ACTION.CHANGE_QUESTION:
        return {
          ...state,
          questionIndex: action.payload
        };
      case 'TEST_TOKEN_REQUEST':
        return {
          ...state,
          isLoading: true
        };
      case 'TEST_TOKEN_FAILED':
        return {
          ...state,
          isLoading: false
        };
      case 'TEST_TOKEN_SUCCESS':
        return {
          ...state,
          isLoading: false
        };
      default:
        return state;
    }
  }
);

export function* saga() {
  yield takeLatest(
    'TEST_TOKEN_REQUEST',
    function* testTokenRequest(action: any): any {
      try {
        const { data } = yield call(API.testToken);
        yield put({
          type: 'TEST_TOKEN_SUCCESS',
          payload: data
        });
      } catch (error) {
        yield put({
          type: 'TEST_TOKEN_FAILED',
          payload: error
        });
        yield put({
          type: ACTION_ALERT.SHOW_ALERT_ERROR,
          payload: error
        });
      }
    }
  );
  yield takeLatest(
    ACTION.START_QUIZ_REQUEST,
    function* startQuizRequest(action: any): any {
      try {
        const { navigate } = action.payload;
        const { data } = yield call(API.startQuiz);
        yield put({
          type: ACTION.START_QUIZ_SUCCESS,
          payload: { data }
        });
        yield put({
          type: ACTION_USER.UPDATE_LATEST_QUESTION_ID
        });
        navigate(`/quiz`);
      } catch (error) {
        yield put({
          type: ACTION.START_QUIZ_FAILED,
          payload: error
        });
        yield put({
          type: ACTION_ALERT.SHOW_ALERT_ERROR,
          payload: error
        });
      }
    }
  );
  yield takeLatest(
    ACTION.GET_RESULT_REQUEST,
    function* getResultRequest(action: any): any {
      try {
        const { data } = yield call(API.finishQuiz);
        yield put({
          type: ACTION.GET_RESULT_SUCCESS,
          payload: data
        });
      } catch (error) {
        yield put({
          type: ACTION.GET_RESULT_FAILED,
          payload: error
        });
        yield put({
          type: ACTION_ALERT.SHOW_POPUP,
          payload:
            error?.response?.data?.errors !== undefined
              ? error?.response?.data?.errors[0].level +
                ' ' +
                error?.response?.data?.errors[0].objectName +
                ' ' +
                error?.response?.data?.errors[0].message
              : error?.response?.data?.message !== undefined
              ? error?.response?.data?.message
              : error?.message !== undefined
              ? error?.message
              : error
        });
      }
    }
  );
  yield takeLatest(
    ACTION.CURRENT_BLOCK_REQUEST,
    function* currentBlockRequest(action: any): any {
      try {
        const { data, headers } = yield call(API.currentBlock);
        yield put({
          type: ACTION.CURRENT_BLOCK_SUCCESS,
          payload: { data, headers }
        });
      } catch (error) {
        yield put({
          type: ACTION.CURRENT_BLOCK_FAILED,
          payload: error
        });
        yield put({
          type: ACTION_ALERT.SHOW_ALERT_ERROR,
          payload:
            error?.response?.data?.errors !== undefined
              ? error?.response?.data?.errors[0].level +
                ' ' +
                error?.response?.data?.errors[0].objectName +
                ' ' +
                error?.response?.data?.errors[0].message
              : error?.response?.data?.message !== undefined
              ? error?.response?.data?.message
              : error?.message !== undefined
              ? error?.message
              : error
        });
      }
    }
  );
  yield takeLatest(
    ACTION.NEXT_BLOCK_REQUEST,
    function* nextBlockRequest(action: any): any {
      try {
        const blockId = yield select(getBlockId);
        const { data, headers } = yield call(API.nextBlock, blockId);
        yield put({
          type: ACTION.NEXT_BLOCK_SUCCESS,
          payload: { data, headers }
        });
      } catch (error) {
        yield put({
          type: ACTION.NEXT_BLOCK_FAILED,
          payload: error
        });
        yield put({
          type: ACTION_ALERT.SHOW_ALERT_ERROR,
          payload: error
        });
      }
    }
  );
  yield takeLatest(
    ACTION.SAVE_AND_NEXT_BLOCK_REQUEST,
    function* saveAndNextBlockRequest(action: any): any {
      try {
        const { blockId, answer } = action.payload;

        yield call(API.answerQuestion, answer);
        yield put({
          type: ACTION.ANSWER_QUESTION_SUCCESS,
          payload: action.payload
        });

        const { data, headers } = yield call(API.nextBlock, blockId);
        yield put({
          type: ACTION.NEXT_BLOCK_SUCCESS,
          payload: { data, headers }
        });
      } catch (error) {
        yield put({
          type: ACTION.NEXT_BLOCK_FAILED,
          payload: error
        });
        yield put({
          type: ACTION_ALERT.SHOW_ALERT_ERROR,
          payload: error
        });
      }
    }
  );
  yield takeLatest(
    ACTION.PREV_BLOCK_REQUEST,
    function* prevBlockRequest(action: any): any {
      try {
        const blockId = yield select(getBlockId);
        const { data, headers } = yield call(API.prevBlock, blockId);
        yield put({
          type: ACTION.PREV_BLOCK_SUCCESS,
          payload: { data, headers }
        });
      } catch (error) {
        yield put({
          type: ACTION.PREV_BLOCK_FAILED,
          payload: error
        });
        yield put({
          type: ACTION_ALERT.SHOW_ALERT_ERROR,
          payload: error
        });
      }
    }
  );
  yield takeLatest(
    ACTION.ANSWER_QUESTION_REQUEST,
    function* answerQuestionRequest(action: any): any {
      try {
        const { data } = yield call(API.answerQuestion, action.payload);
        yield put({
          type: ACTION.ANSWER_QUESTION_SUCCESS,
          payload: action.payload
        });
        // yield put({
        //   type: ACTION.NEXT_QUESTION
        // })
      } catch (error) {
        yield put({
          type: ACTION.ANSWER_QUESTION_FAILED,
          payload: error
        });
        yield put({
          type: ACTION_ALERT.SHOW_ALERT_ERROR,
          payload:
            error?.response?.data?.errors !== undefined
              ? error?.response?.data?.errors[0].level +
                ' ' +
                error?.response?.data?.errors[0].objectName +
                ' ' +
                error?.response?.data?.errors[0].message
              : error?.response?.data?.message !== undefined
              ? error?.response?.data?.message
              : error?.message !== undefined
              ? error?.message
              : error
        });
      }
    }
  );
  yield takeLatest(
    ACTION.NEW_NEXT_QUESTION_REQUEST,
    function* newNextQuestionRequest(action: any): any {
      try {
        const { answerBody, navigate } = action.payload;
        const isLastQuestionQuiz = yield select(getIsLastQuestionQuiz);
        const isImmutableResponse = yield select(getImmutableResponse);
        const isLastQuestionBlock = yield select(getIsLastQuestionBlock);
        let resultAnswerConfirmation = {
          isConfirmed: true
        };

        if (
          !answerBody?.textAnswer &&
          (!answerBody?.mcAnswer || answerBody?.mcAnswer?.includes(''))
        ) {
          yield call(EmptyAnswerConfirmation);
          return;
        }

        if (isImmutableResponse) {
          resultAnswerConfirmation = yield call(AnswerConfirmation);
        }

        if (resultAnswerConfirmation.isConfirmed) {
          const { data } = yield call(API.answerQuestion, answerBody);
          yield put({
            type: ACTION.ANSWER_QUESTION_SUCCESS,
            payload: answerBody
          });

          if (isLastQuestionQuiz) {
            yield put({
              type: ACTION.NEW_FINISH_QUIZ_REQUEST,
              payload: { navigate }
            });
          } else if (isLastQuestionBlock) {
            yield put({
              type: ACTION.NEXT_BLOCK_REQUEST
            });
          } else {
            yield put({
              type: ACTION.NEW_NEXT_QUESTION_SUCCESS
            });
          }
        }
      } catch (error) {
        yield put({
          type: ACTION.NEW_NEXT_QUESTION_FAILED,
          payload: error
        });
      }
    }
  );
  yield takeLatest(
    ACTION.NEW_PREV_QUESTION_REQUEST,
    function* newPrevQuestionRequest(action: any): any {
      try {
        const isFirstQuestionBlock = yield select(getIsFirstQuestionBlock);

        if (isFirstQuestionBlock) {
          yield put({
            type: ACTION.PREV_BLOCK_REQUEST
          });
        } else {
          yield put({
            type: ACTION.NEW_PREV_QUESTION_SUCCESS
          });
        }
      } catch (error) {
        yield put({
          type: ACTION.NEW_PREV_QUESTION_FAILED,
          payload: error
        });
      }
    }
  );
  yield takeLatest(
    ACTION.NEW_SKIP_QUESTION_REQUEST,
    function* newSkipQuestionRequest(action: any): any {
      try {
        const isLastQuestionBlock = yield select(getIsLastQuestionBlock);

        if (isLastQuestionBlock) {
          yield put({
            type: ACTION.NEXT_BLOCK_REQUEST
          });
        } else {
          yield put({
            type: ACTION.NEW_SKIP_QUESTION_SUCCESS
          });
        }
      } catch (error) {
        yield put({
          type: ACTION.NEW_SKIP_QUESTION_FAILED,
          payload: error
        });
      }
    }
  );
  yield takeLatest(
    ACTION.NEW_FINISH_QUIZ_REQUEST,
    function* newFinishQuizRequest(action: any): any {
      try {
        const { navigate } = action.payload;
        const progress = yield call(API.checkProgress);

        const { result } = yield call(FinishQuiz, progress.data);

        if (!result.isConfirmed) {
          yield put({
            type: ACTION.NEW_FINISH_QUIZ_FAILED
          });
          return;
        }

        const finish = yield call(API.finishQuiz);

        yield put({
          type: ACTION.NEW_FINISH_QUIZ_SUCCESS,
          payload: finish.data
        });

        navigate('/result');
      } catch (error) {
        yield put({
          type: ACTION.NEW_FINISH_QUIZ_FAILED,
          payload: error
        });
      }
    }
  );
  yield takeLatest(
    ACTION.TIMESUP_REQUEST,
    function* timesupRequest(action: any): any {
      try {
        const { navigate } = action.payload;

        const { result } = yield call(Timesup);

        if (!result.isConfirmed) return;

        const finish = yield call(API.finishQuiz);

        yield put({
          type: ACTION.NEW_FINISH_QUIZ_SUCCESS,
          payload: finish.data
        });

        yield put({
          type: ACTION.TIMESUP_SUCCESS
        });

        navigate('/result');
      } catch (error) {
        yield put({
          type: ACTION.TIMESUP_FAILED,
          payload: error
        });
      }
    }
  );
}
