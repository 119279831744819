import { createContext, useContext, useMemo } from 'react';

type I18nContext = {
  selectedLang: string;
};
const I18nContext = createContext<I18nContext>({} as I18nContext);

const I18N_CONFIG_KEY = process.env.I18N_CONFIG_KEY || 'i18nConfig';
const initialState = {
  selectedLang: 'en'
};

function getConfig() {
  const ls = localStorage.getItem(I18N_CONFIG_KEY);

  if (ls) {
    try {
      return JSON.parse(ls);
    } catch (err) {
      console.log('error parsing on get config', err);
    }
    return initialState;
  }
}

export function setLanguage(lang) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ selectedLang: lang }));
  window.location.reload();
}

export function useLang(lang = 'en') {
  const context = useContext(I18nContext);
  return context ? context.selectedLang : lang;
}

export function MyI18nProvider({ children }) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const lang = useMemo(getConfig, []);

  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>;
}
