import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { ReactComponent as BottomRight } from 'src/assets/images/background_bottom_right.svg';
import { ReactComponent as BottomLeft } from 'src/assets/images/background_top_left.svg';
import FadeSnackbar from 'src/components/Feedback/FadeSnackbar';

interface BackgroundLayoutProps {
  children?: ReactNode;
}

const MainWrapper = styled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        overflow: auto;
`
);

const CustomBottomRight = styled(BottomRight)(
  ({ theme }) => `
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: -1;
`
);

const CustomBottomLeft = styled(BottomLeft)(
  ({ theme }) => `
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
`
);

const BackgroundLayout: FC<BackgroundLayoutProps> = ({ children }) => {
  return (
    <>
      <MainWrapper>
        <CustomBottomLeft />
        <MainContent>
          <FadeSnackbar />
          <Outlet />
        </MainContent>
        <CustomBottomRight />
      </MainWrapper>
    </>
  );
};

BackgroundLayout.propTypes = {
  children: PropTypes.node
};

export default BackgroundLayout;
