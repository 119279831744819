import * as React from 'react';
import { Global } from '@emotion/react';
import { styled } from '@mui/material/styles';
import { grey } from '@mui/material/colors';
import Box from '@mui/material/Box';
import { Divider, Drawer, IconButton } from '@mui/material';
import NavigationQuestion from 'src/pages/Quiz/navigation';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { useContext } from 'react';
import { SidebarContext } from 'src/contexts/SidebarContext';

const StyledBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : grey[800],
  boxShadow: theme.sidebar.boxShadow
}));

const TopSection = styled(Box)(
  ({ theme }) => `
        display: flex;
        height: 88px;
        align-items: center;
        margin: 0 ${theme.spacing(2)} ${theme.spacing(2)};
`
);

export default function SwipeableEdgeDrawer() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  return (
    <>
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: `calc(100%)`,
            overflow: 'visible'
          }
        }}
      />
      <Drawer
        anchor="left"
        open={sidebarToggle}
        onClose={toggleSidebar}
        ModalProps={{
          keepMounted: true
        }}
      >
        <StyledBox
          sx={{
            position: 'absolute',
            top: 0,
            right: '-43px',
            visibility: 'visible',
            height: '100%',
            alignItems: 'center',
            display: 'flex',
            color: '#159ab1'
          }}
        >
          {!sidebarToggle && (
            <IconButton color="inherit" onClick={toggleSidebar}>
              <ArrowForwardIosIcon color="inherit" />
            </IconButton>
          )}
          {sidebarToggle && (
            <IconButton color="inherit" onClick={toggleSidebar}>
              <ArrowBackIosNewIcon color="inherit" />
            </IconButton>
          )}
        </StyledBox>
        <Divider variant="middle" />
        <StyledBox
          sx={{
            height: '100%',
            overflow: 'auto',
            width: '280px'
          }}
        >
          <TopSection></TopSection>
          <NavigationQuestion />
        </StyledBox>
      </Drawer>
    </>
  );
}
