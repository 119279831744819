import { Alert, Fade, Snackbar } from '@mui/material';
import React from 'react';
import { connect } from 'react-redux';
import { hideAlert } from '../../actions/fadeSnackbar';

class FadeSnackbar extends React.Component<{
  fadeSnackbar: any;
  hideAlert: any;
}> {
  componentWillUnmount(): void {
    this.props.hideAlert();
  }

  render(): React.ReactNode {
    return (
      <>
        {this.props.fadeSnackbar.open &&
          this.props.fadeSnackbar.text !== 'DONT_SHOW' && (
            <Fade in={this.props.fadeSnackbar.open}>
              <Snackbar
                open={this.props.fadeSnackbar.open}
                autoHideDuration={6000}
                onClose={this.props.hideAlert}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              >
                <Alert
                  onClose={this.props.hideAlert}
                  severity={this.props.fadeSnackbar.severity}
                  sx={{ width: '100%' }}
                >
                  {this.props.fadeSnackbar.text}
                </Alert>
              </Snackbar>
            </Fade>
          )}
      </>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    fadeSnackbar: state.fadeSnackbar
  };
};

export default connect(mapStateToProps, { hideAlert })(FadeSnackbar);
