import * as ACTION from '../constants/user';
import * as ACTION_QUIZ from '../constants/quiz'

const login = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION.LOGIN_REQUEST,
    payload
  });
};

const logout = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION.LOGOUT,
    payload
  });
  dispatch({
    type: ACTION_QUIZ.EMPTY_DATA_QUIZ
  });
};

export { login, logout };
