import { FC, useState, createContext } from 'react';
type QuestionIndexContext = {
  questionIndex: number;
  changeQuestionIndex: (value) => void;
  plusQuestionIndex: () => void;
  minusQuestionIndex: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const QuestionIndexContext = createContext<QuestionIndexContext>(
  {} as QuestionIndexContext
);

export const QuestionIndexProvider: FC = ({ children }) => {
  const [questionIndex, setQuestionIndex] = useState(0);

  const changeQuestionIndex = (value) => {
    setQuestionIndex(value);
  };
  const plusQuestionIndex = () => {
    setQuestionIndex(questionIndex + 1);
  };
  const minusQuestionIndex = () => {
    setQuestionIndex(questionIndex - 1);
  };

  return (
    <QuestionIndexContext.Provider
      value={{
        questionIndex,
        changeQuestionIndex,
        plusQuestionIndex,
        minusQuestionIndex
      }}
    >
      {children}
    </QuestionIndexContext.Provider>
  );
};
