import * as ACTION from '../../constants/user';
import * as ACTION_ALERT from '../../constants/fadeSnackbar';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import * as API from '../../api/User';
import { httpApi } from 'src/helpers/axios';

const initialState = {};

export const reducer = persistReducer(
  { storage, key: 'X-User' },
  (state: any = initialState, action: any) => {
    switch (action.type) {
      case ACTION.LOGIN_REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case ACTION.LOGIN_FAILED:
        return {
          ...state,
          isLoading: false
        };
      case ACTION.LOGIN_SUCCESS:
        return {
          ...state,
          ...action.payload,
          isLoading: false
        };
      case ACTION.LOGOUT:
        action.payload.navigate('/');
        return {
          isLoading: true
        };
      case ACTION.RENEW_TOKEN_REQUEST:
        return {
          ...state,
          isLoading: true
        };
      case ACTION.RENEW_TOKEN_FAILED:
        return {
          ...state,
          isLoading: false
        };
      case ACTION.RENEW_TOKEN_SUCCESS:
        return {
          ...state,
          ...action.payload,
          isLoading: false
        };
      case ACTION.UPDATE_LATEST_QUESTION_ID:
        return {
          ...state,
          latestQuestionId: 'dummy'
        };
      default:
        return state;
    }
  }
);

export function* saga() {
  yield takeLatest(
    ACTION.LOGIN_REQUEST,
    function* loginRequest(action: any): any {
      try {
        const { values, navigate } = action.payload;

        const data = yield call(API.login, values);
        yield put({
          type: ACTION.LOGIN_SUCCESS,
          payload: data
        });
        navigate('/welcome');
      } catch (error) {
        yield put({
          type: ACTION.LOGIN_FAILED,
          payload: error
        });
        yield put({
          type: ACTION_ALERT.SHOW_ALERT_ERROR,
          payload:
            error?.response?.data?.errors !== undefined
              ? error?.response?.data?.errors[0].level +
                ' ' +
                error?.response?.data?.errors[0].objectName +
                ' ' +
                error?.response?.data?.errors[0].message
              : error?.response?.data?.message !== undefined
              ? error?.response?.data?.message
              : error?.message !== undefined
              ? error?.message
              : error
        });
      }
    }
  );
  yield takeLatest(
    ACTION.RENEW_TOKEN_REQUEST,
    function* renewTokenRequest(action: any): any {
      try {
        const { prevRequest } = action.payload;
        const data = yield call(API.renewToken, action.payload);

        yield put({
          type: ACTION.RENEW_TOKEN_SUCCESS,
          payload: data
        });
        // yield call(API.recallEndpoint, prevRequest);
      } catch (error) {
        yield put({
          type: ACTION.RENEW_TOKEN_FAILED,
          payload: error
        });
        yield put({
          type: ACTION_ALERT.SHOW_ALERT_ERROR,
          payload:
            error?.response?.data?.errors !== undefined
              ? error?.response?.data?.errors[0].level +
                ' ' +
                error?.response?.data?.errors[0].objectName +
                ' ' +
                error?.response?.data?.errors[0].message
              : error?.response?.data?.message !== undefined
              ? error?.response?.data?.message
              : error?.message !== undefined
              ? error?.message
              : error
        });
      }
    }
  );
}
