import { useLocation, Navigate, useParams, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';

function WelcomeRouter(props) {
  const { latestQuestionId, finishTime, children } = props;
  let location = useLocation();

  if (latestQuestionId !== null) {
    return <Navigate to="/quiz" state={{ from: location }} replace />;
  }

  // if (finishTime !== null || finishTime !== undefined) {
  //   return <Navigate to="/quiz" state={{ from: location }} replace />;
  // }

  return <Outlet />;
}

const mapStateToProps = (state: any) => {
  return {
    latestQuestionId: state.user.latestQuestionId,
    finishTime: state.quiz.finishTime
  };
};

export default connect(mapStateToProps, {})(WelcomeRouter);
