import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';

function QuizRouter(props) {
  const { finishTime, results, latestQuestionId, children } = props;

  let location = useLocation();

  if (results?.finished === true) {
    return <Navigate to="/result" state={{ from: location }} replace />;
  }

  if (latestQuestionId === null) {
    return <Navigate to="/welcome" state={{ from: location }} replace />;
  }

  // if (finishTime === null || finishTime === undefined) {
  //   return <Navigate to="/welcome" state={{ from: location }} replace />;
  // }

  return <Outlet />;
}

const mapStateToProps = (state: any) => {
  return {
    results: state.quiz.results,
    latestQuestionId: state.user.latestQuestionId,
    finishTime: state.quiz.finishTime
  };
};

export default connect(mapStateToProps, {})(QuizRouter);
