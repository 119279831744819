import { httpApi } from 'src/helpers/axios';

const startQuiz = () => {
  return httpApi.post('quiz/start');
};

const finishQuiz = () => {
  return httpApi.post('quiz/finish');
};

const nextBlock = (blockId) => {
  return httpApi.get(`quiz/blocks/${blockId}/next`);
};

const prevBlock = (blockId) => {
  return httpApi.get(`quiz/blocks/${blockId}/prev`);
};

const currentBlock = () => {
  return httpApi.get(`quiz/blocks/current`);
};

const checkProgress = () => {
  return httpApi.get(`quiz/progress`);
};

const answerQuestion = (payload) => {
  return httpApi.post('questions/answer', payload, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

const skipQuestion = (payload) => {
  return httpApi.post('questions/emptyAnswer', payload, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

const testToken = () => {
  return httpApi.get('testToken');
};

export {
  startQuiz,
  testToken,
  nextBlock,
  prevBlock,
  currentBlock,
  answerQuestion,
  finishQuiz,
  checkProgress,
  skipQuestion
};
