import App from './App';
import ReactDOM from 'react-dom';
import 'src/utils/chart';
import * as serviceWorker from './serviceWorker';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter } from 'react-router-dom';

import 'nprogress/nprogress.css';
import { SidebarProvider } from './contexts/SidebarContext';
import { ModalProvider } from './contexts/ModalContext';
import { QuestionIndexProvider } from './contexts/QuestionIndexContext';
import { Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import reducer from './reducers';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import reduxLogger from 'redux-logger';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';
import { rootSaga } from './reducers/index';
import { configureStore } from '@reduxjs/toolkit';
import { CookiesProvider } from 'react-cookie';
import setupInterceptor from './helpers/interceptor';
import { MyI18nProvider, I18nProvider } from './i18n/index';
import { MathJaxContext } from 'better-react-mathjax';

const middleware: any = [thunk];
if (process.env.NODE_ENV !== 'production') {
  middleware.push(reduxLogger);
}

const sagaMiddleware = createSagaMiddleware();
middleware.push(sagaMiddleware);

// const store = createStore(reducer, applyMiddleware(...middleware));
const store = configureStore({
  reducer,
  middleware
});
sagaMiddleware.run(rootSaga);

const persistor = persistStore(store);
setupInterceptor(store);

ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <MyI18nProvider>
        <I18nProvider>
          <HelmetProvider>
            <CookiesProvider>
              <SidebarProvider>
                <ModalProvider>
                  <QuestionIndexProvider>
                    <BrowserRouter>
                      <MathJaxContext>
                        <App />
                      </MathJaxContext>
                    </BrowserRouter>
                  </QuestionIndexProvider>
                </ModalProvider>
              </SidebarProvider>
            </CookiesProvider>
          </HelmetProvider>
        </I18nProvider>
      </MyI18nProvider>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
