import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { Suspense, lazy, Children } from 'react';
import { Navigate } from 'react-router-dom';

import SidebarPreviewLayout from 'src/layouts/SidebarPreviewLayout';
import BaseLayout from 'src/layouts/BaseLayout';
import BackgroundLayout from 'src/layouts/BackgroundLayout';
import HeaderLayout from 'src/layouts/HeaderLayout';

import SuspenseLoader from 'src/components/SuspenseLoader';

import RequireAuth from './RequireAuth';
import NotRequireAuth from './NotRequireAuth';
import QuizRouter from './QuizRouter';
import ResultRouter from './ResultRouter';
import WelcomeRouter from './WelcomeRouter';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Login
const Login = Loader(lazy(() => import('src/pages/Login')));
const Logout = Loader(lazy(() => import('src/pages/Logout')));

// Welcome
const Welcome = Loader(lazy(() => import('src/pages/Welcome')));

//Certificate
const Certificate = Loader(lazy(() => import('src/pages/Certificate')));
const CertificateDetail = Loader(
  lazy(() => import('src/pages/Certificate/CertificateDetail'))
);

// Quiz
const Quiz = Loader(lazy(() => import('src/pages/Quiz')));

// Result
const Result = Loader(lazy(() => import('src/pages/Result')));

// Status
const Status404 = Loader(lazy(() => import('src/pages/Status/Status404')));

function Router() {
  return (
    <Routes>
      <Route element={<BaseLayout />}>
        <Route path="/">
          <Navigate to="/login" replace />
        </Route>
        <Route element={<BackgroundLayout />}>
          <Route element={<NotRequireAuth />}>
            <Route path="/login" element={<Login />} />
          </Route>

          <Route path="/certificate" element={<Certificate />} />
        </Route>

        <Route element={<RequireAuth />}>
          <Route element={<HeaderLayout />}>
            <Route element={<WelcomeRouter />}>
              <Route path="/welcome" element={<Welcome />} />
            </Route>
            <Route element={<ResultRouter />}>
              <Route path="/result" element={<Result />} />
            </Route>
          </Route>

          <Route element={<SidebarPreviewLayout />}>
            <Route element={<QuizRouter />}>
              <Route path="/quiz" element={<Quiz />} />
            </Route>
          </Route>
        </Route>

        <Route path="/certificate/:id" element={<CertificateDetail />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="*" element={<Status404 />} />
      </Route>
    </Routes>
  );
}

export default Router;
