import axios from 'axios';

const httpApi = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

const httpPublic = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_API_URL
});

const httpClient = axios.create({
  baseURL: process.env.REACT_APP_CLIENT_URL
});

export { httpApi, httpClient, httpPublic };
