import * as ACTION from '../../constants/livecode';
import * as ACTION_ALERT from '../../constants/fadeSnackbar';
import * as API from '../../api/Livecode';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { call, put, takeLatest } from 'redux-saga/effects';

const initialState = {};

export const reducer = persistReducer(
  { storage, key: 'X-livecode' },
  (state: any = initialState, action: any) => {
    switch (action.type) {
      case ACTION.RUN_LIVECODE_REQUEST:
        return {
          ...state
        };
      case ACTION.RUN_LIVECODE_FAILED:
        return {
          ...state
        };
      case ACTION.RUN_LIVECODE_SUCCESS:
        return {
          ...state
        };
      default:
        return state;
    }
  }
);

export function* saga() {
  yield takeLatest(
    ACTION.RUN_LIVECODE_REQUEST,
    function* runLivecodeRequest(action: any): any {
      try {
        const data = yield call(API.runLivecode, action.payload);
        yield put({
          type: ACTION.RUN_LIVECODE_SUCCESS,
          payload: data
        });
      } catch (error) {
        yield put({
          type: ACTION.RUN_LIVECODE_FAILED,
          payload: error
        });
        yield put({
          type: ACTION_ALERT.SHOW_ALERT_ERROR,
          payload:
            error?.response?.data?.errors !== undefined
              ? error?.response?.data?.errors[0].level +
                ' ' +
                error?.response?.data?.errors[0].objectName +
                ' ' +
                error?.response?.data?.errors[0].message
              : error?.response?.data?.message !== undefined
              ? error?.response?.data?.message
              : error?.message !== undefined
              ? error?.message
              : error
        });
      }
    }
  );
}
