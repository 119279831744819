import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { createIntl, createIntlCache } from 'react-intl';
import enMessages from '../../i18n/messages/en.json';
import idMessages from '../../i18n/messages/id.json';

const MySwal = withReactContent(Swal);
const allMessages = {
  en: enMessages,
  id: idMessages
};

const Timesup = async () => {
  const locale =
    JSON.parse(localStorage.getItem('i18nConfig'))?.selectedLang || 'en';
  const messages = allMessages[locale];

  const cache = createIntlCache();
  const intl = createIntl(
    {
      locale,
      messages
    },
    cache
  );

  return await MySwal.fire({
    icon: 'warning',
    title: intl.formatMessage({ id: 'MODAL_TIMESUP_TITLE' }),
    text: intl.formatMessage({
      id: 'MODAL_TIMESUP_DESCRIPTION'
    }),
    confirmButtonText: intl.formatMessage({
      id: 'MODAL_TIMESUP_SUBMIT'
    })
  }).then((result) => {
    return { result };
  });
};

export default Timesup;
