import { FC, ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';

import Header from './Header';
import FadeSnackbar from 'src/components/Feedback/FadeSnackbar';

const MainWrapper = styled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
`
);

const MainContent = styled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow: auto;
`
);

const SidebarPreviewLayout = () => {
  return (
    <>
      <MainWrapper>
        <Header />
        <MainContent>
          <FadeSnackbar />
          <Outlet />
        </MainContent>
      </MainWrapper>
    </>
  );
};

export default SidebarPreviewLayout;
