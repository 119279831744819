import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { connect } from 'react-redux';
import { options } from 'numeral';

const ButtonQuestionNav = styled(Button)(
  ({ theme }) => `
         background: ${theme.palette.primary.contrastText};
         color: #666666;
         border: 1px solid #CCCCCC;
         font-weight:500;
         padding: 6px;
         min-width: 39px;
  
         &:hover {
            background: #f0f0f0;
         }
        `
);

const ButtonQuestionNavAnswered = styled(Button)(
  ({ theme }) => `
        background: #FDF0E6;
        color: #CD9263;
        border: 1px solid #CD9263;
        font-weight:500;
        padding: 6px;
        min-width: 39px;

        &:hover {
        background: rgba(253, 240, 230, 0.7)
        }
    `
);

const ButtonQuestionNavSelected = styled(Button)(
  ({ theme }) => `
         background: #D7FFFA;
         color: #159AB1;
         border: 1px solid #159AB1;
         font-weight:500;
         padding: 6px;
         min-width: 39px;
  
         &:hover {
            background: rgba(215,255,250,0.7)
         }
        `
);

const ButtonQuestionNavUnanswered = styled(Button)(
  ({ theme }) => `
         background: #CCCCCC;
         color: #999999;
         border: 1px solid #CCCCCC;
         font-weight:500;
         padding: 6px;
         min-width: 39px;
        `
);

type ButtonQuestionNavProps = {
  questionState?: 'unselected' | 'selected' | 'unanswered' | 'answered';
  answered: boolean;
  textResponse: any;
  options: any;
  children: React.ReactNode;
  onClick: any;
  quizFlow: 'FO' | 'FB';
};

function ButtonQuestionNavTemp(props: ButtonQuestionNavProps) {
  const {
    children,
    questionState,
    onClick,
    quizFlow,
    answered,
    textResponse,
    options
  } = props;

  // if (questionState === 'selected')
  //   return (
  //     <ButtonQuestionNavSelected disabled={quizFlow === 'FO' ? true : false}>
  //       {children}
  //     </ButtonQuestionNavSelected>
  //   );
  // else if (questionState === 'unselected')
  //   return (
  //     <ButtonQuestionNav
  //       onClick={onClick}
  //       disabled={quizFlow === 'FO' ? true : false}
  //     >
  //       {children}
  //     </ButtonQuestionNav>
  //   );
  // else if (questionState === 'answered')
  //   return (
  //     <ButtonQuestionNavAnswered
  //       onClick={onClick}
  //       disabled={quizFlow === 'FO' ? true : false}
  //     >
  //       {children}
  //     </ButtonQuestionNavAnswered>
  //   );
  // else if (questionState === 'unanswered' && quizFlow === 'FO')
  //   return (
  //     <ButtonQuestionNavUnanswered disabled>
  //       {children}
  //     </ButtonQuestionNavUnanswered>
  //   );
  // else if (questionState === 'unanswered' && quizFlow === 'FB')
  //   return <ButtonQuestionNav onClick={onClick}>{children}</ButtonQuestionNav>;

  if (answered) {
    return (
      <ButtonQuestionNavAnswered
        onClick={onClick}
        disabled={quizFlow === 'FO' ? true : false}
      >
        {children}
      </ButtonQuestionNavAnswered>
    );
  }else if (
    !answered &&
    (textResponse === null ||
      textResponse === '' ||
      options.filter((e) => e.selected === true).length === 0)
  ) {
    return (
      <ButtonQuestionNav
        onClick={onClick}
        disabled={quizFlow === 'FO' ? true : false}
      >
        {children}
      </ButtonQuestionNav>
    );
  }else {
    return (
      <ButtonQuestionNavUnanswered disabled>
        {children}
      </ButtonQuestionNavUnanswered>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    quizFlow: state.quiz.quizFlow
  };
};

export default connect(mapStateToProps)(ButtonQuestionNavTemp);
