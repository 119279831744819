import { httpApi, httpClient } from 'src/helpers/axios';

const login = (payload) => {
  return httpClient.post('login', payload, {
    headers: {
      'Content-Type': 'application/json'
    }
  });
};

const renewToken = (payload) => {
  return httpClient.post(
    'refresh',
    { refreshToken: payload },
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );
};

const recallEndpoint = (prevRequest) => {
  return httpApi(prevRequest);
};

export { login, renewToken, recallEndpoint };
