import {
  Grid,
  CardActions,
  Paper,
  CardContent,
  Divider,
  Typography,
  IconButton
} from '@mui/material';
import ButtonOutlinedPrimaryDQLab from 'src/components/Button/ButtonOutlinedPrimaryDQLab';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { connect } from 'react-redux';
import { Box } from '@mui/system';
import { useContext } from 'react';
import { newFinishQuiz, changeQuestion } from '../../../actions/quiz';
import { useNavigate } from 'react-router';
import { SidebarContext } from 'src/contexts/SidebarContext';
import MiniForwardOnlyQuiz from './MiniForwardOnlyQuiz';
import { nextBlock, prevBlock } from 'src/actions/quiz';
import ButtonQuestionNavTemp from 'src/components/Button/ButtonQuestionNavTemp';
import { FormattedMessage, injectIntl } from 'react-intl';

function NavigationQuestion(props) {
  const {
    quizBlock,
    quizFlow,
    nextBlock,
    prevBlock,
    newFinishQuiz,
    changeQuestion
  } = props;
  const navigate = useNavigate();
  const { toggleSidebar } = useContext(SidebarContext);

  const navigateQuestion = (value) => {
    changeQuestion(value);
    toggleSidebar();
  };

  return (
    <Box p={1}>
      {/* <Card sx={{ position: 'sticky', top: '1rem', minWidth: '290px' }}> */}
      <Grid container p={2} justifyContent="space-between" alignItems="center">
        <Grid item xs={1}>
          <IconButton
            aria-label="prev-block"
            disabled={
              quizBlock?.prev === null || quizFlow === 'FO' ? true : false
            }
            onClick={() => {
              prevBlock();
            }}
          >
            <ArrowBackIosIcon sx={{ fontSize: '13px' }} />
          </IconButton>
        </Grid>
        <Grid item>
          <Typography
            variant="caption"
            sx={{
              color: 'black',
              textTransform: 'capitalize'
            }}
          >
            {quizBlock?.title}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <IconButton
            aria-label="next-block"
            disabled={
              quizBlock?.next === null || quizFlow === 'FO' ? true : false
            }
            onClick={() => {
              nextBlock();
            }}
          >
            <ArrowForwardIosIcon sx={{ fontSize: '13px' }} />
          </IconButton>
        </Grid>
      </Grid>
      <Divider variant="middle" />
      <CardContent>
        <Grid container spacing={1}>
          {quizBlock?.questions?.map((item, index) => {
            return (
              <Grid
                item
                xs
                key={'navQuestion-' + index}
                sx={{ maxWidth: '53px' }}
              >
                <ButtonQuestionNavTemp
                  onClick={() => navigateQuestion(index)}
                  answered={item?.answered}
                  textResponse={item?.textResponse}
                  options={item?.options?.answers}
                  // questionState={
                  //   quizBlock?.questionIndex === index
                  //     ? 'selected'
                  //     : item?.questionState === undefined
                  //     ? 'unselected'
                  //     : item?.questionState
                  // }
                >
                  {index + 1}
                </ButtonQuestionNavTemp>
              </Grid>
            );
          })}
        </Grid>
      </CardContent>
      {quizFlow === 'FO' && (
        <Box sx={{ p: 2 }}>
          <MiniForwardOnlyQuiz />
        </Box>
      )}
      {/* {quizFlow === 'FB' && ( */}
      <CardActions sx={{ padding: 2, pt: 0 }}>
        <Grid container justifyContent="space-between">
          <Grid item xs={12}>
            <Paper elevation={20}>
              <ButtonOutlinedPrimaryDQLab
                fullWidth
                onClick={() => {
                  newFinishQuiz({ navigate });
                }}
              >
                <FormattedMessage id="FINISH" />
              </ButtonOutlinedPrimaryDQLab>
            </Paper>
          </Grid>
        </Grid>
      </CardActions>
      {/* )} */}

      {/* <FinishQuiz /> */}
      {/* </Card> */}
    </Box>
  );
}

const mapStateToProps = (state: any) => {
  return {
    quizBlock: state.quiz.quizBlock,
    quizFlow: state.quiz.quizFlow
  };
};

export default connect(mapStateToProps, {
  nextBlock,
  prevBlock,
  newFinishQuiz,
  changeQuestion
})(injectIntl(NavigationQuestion));
