import { FC, useState, createContext } from 'react';
type ConnectionOfflineContext = {
  isConnectionOffline: any;
  closeAlert: () => void;
  openAlert: () => void;
  toggleAlert: () => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ConnectionOfflineContext = createContext<ConnectionOfflineContext>(
  {} as ConnectionOfflineContext
);

export const ConnectionOfflineProvider: FC = ({ children }) => {
  const [isConnectionOffline, setIsConnectionOffline] = useState(false);
  const closeAlert = () => {
    setIsConnectionOffline(false);
  };
  const openAlert = () => {
    setIsConnectionOffline(true);
  };
  const toggleAlert = () => {
    setIsConnectionOffline(!isConnectionOffline);
  };

  return (
    <ConnectionOfflineContext.Provider
      value={{ isConnectionOffline, closeAlert, openAlert, toggleAlert }}
    >
      {children}
    </ConnectionOfflineContext.Provider>
  );
};
