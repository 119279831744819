const START_QUIZ_REQUEST = 'START_QUIZ_REQUEST';
const START_QUIZ_FAILED = 'START_QUIZ_FAILED';
const START_QUIZ_SUCCESS = 'START_QUIZ_SUCCESS';
const NEXT_BLOCK_REQUEST = 'NEXT_BLOCK_REQUEST';
const NEXT_BLOCK_FAILED = 'NEXT_BLOCK_FAILED';
const NEXT_BLOCK_SUCCESS = 'NEXT_BLOCK_SUCCESS';
const SAVE_AND_NEXT_BLOCK_REQUEST = 'SAVE_AND_NEXT_BLOCK_REQUEST';
const SAVE_AND_NEXT_BLOCK_FAILED = 'SAVE_AND_NEXT_BLOCK_FAILED';
const SAVE_AND_NEXT_BLOCK_SUCCESS = 'SAVE_AND_NEXT_BLOCK_SUCCESS';
const PREV_BLOCK_REQUEST = 'PREV_BLOCK_REQUEST';
const PREV_BLOCK_FAILED = 'PREV_BLOCK_FAILED';
const PREV_BLOCK_SUCCESS = 'PREV_BLOCK_SUCCESS';
const CURRENT_BLOCK_REQUEST = 'CURRENT_BLOCK_REQUEST';
const CURRENT_BLOCK_FAILED = 'CURRENT_BLOCK_FAILED';
const CURRENT_BLOCK_SUCCESS = 'CURRENT_BLOCK_SUCCESS';
const ANSWER_QUESTION_REQUEST = 'ANSWER_QUESTION_REQUEST';
const ANSWER_QUESTION_FAILED = 'ANSWER_QUESTION_FAILED';
const ANSWER_QUESTION_SUCCESS = 'ANSWER_QUESTION_SUCCESS';
const CHANGE_QUESTION = 'CHANGE_QUESTION';
const EMPTY_DATA_QUIZ = 'EMPTY_DATA_QUIZ';
const GET_RESULT_REQUEST = 'GET_RESULT_REQUEST';
const GET_RESULT_FAILED = 'GET_RESULT_FAILED';
const GET_RESULT_SUCCESS = 'GET_RESULT_SUCCESS';
const CLEANUP_RESULT = 'CLEANUP_RESULT';

export const NEW_NEXT_QUESTION_REQUEST = 'NEW_NEXT_QUESTION_REQUEST';
export const NEW_NEXT_QUESTION_FAILED = 'NEW_NEXT_QUESTION_FAILED';
export const NEW_NEXT_QUESTION_SUCCESS = 'NEW_NEXT_QUESTION_SUCCESS';
export const NEW_SKIP_QUESTION_REQUEST = 'NEW_SKIP_QUESTION_REQUEST';
export const NEW_SKIP_QUESTION_FAILED = 'NEW_SKIP_QUESTION_FAILED';
export const NEW_SKIP_QUESTION_SUCCESS = 'NEW_SKIP_QUESTION_SUCCESS';
export const NEW_PREV_QUESTION_REQUEST = 'NEW_PREV_QUESTION_REQUEST';
export const NEW_PREV_QUESTION_FAILED = 'NEW_PREV_QUESTION_FAILED';
export const NEW_PREV_QUESTION_SUCCESS = 'NEW_PREV_QUESTION_SUCCESS';
export const NEW_FINISH_QUIZ_REQUEST = 'NEW_FINISH_QUIZ_REQUEST';
export const NEW_FINISH_QUIZ_FAILED = 'NEW_FINISH_QUIZ_FAILED';
export const NEW_FINISH_QUIZ_SUCCESS = 'NEW_FINISH_QUIZ_SUCCESS';
export const TIMESUP_REQUEST = 'TIMESUP_REQUEST';
export const TIMESUP_FAILED = 'TIMESUP_FAILED';
export const TIMESUP_SUCCESS = 'TIMESUP_SUCCESS';

export {
  START_QUIZ_REQUEST,
  START_QUIZ_FAILED,
  START_QUIZ_SUCCESS,
  NEXT_BLOCK_REQUEST,
  NEXT_BLOCK_FAILED,
  NEXT_BLOCK_SUCCESS,
  PREV_BLOCK_REQUEST,
  PREV_BLOCK_FAILED,
  PREV_BLOCK_SUCCESS,
  CURRENT_BLOCK_REQUEST,
  CURRENT_BLOCK_FAILED,
  CURRENT_BLOCK_SUCCESS,
  ANSWER_QUESTION_REQUEST,
  ANSWER_QUESTION_FAILED,
  ANSWER_QUESTION_SUCCESS,
  CHANGE_QUESTION,
  EMPTY_DATA_QUIZ,
  SAVE_AND_NEXT_BLOCK_REQUEST,
  SAVE_AND_NEXT_BLOCK_FAILED,
  SAVE_AND_NEXT_BLOCK_SUCCESS,
  CLEANUP_RESULT,
  GET_RESULT_REQUEST,
  GET_RESULT_FAILED,
  GET_RESULT_SUCCESS
};
