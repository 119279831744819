import { useContext, useRef, useState } from 'react';

import { NavLink } from 'react-router-dom';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';

import InboxTwoToneIcon from '@mui/icons-material/InboxTwoTone';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@mui/icons-material/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@mui/icons-material/LockOpenTwoTone';
import AccountTreeTwoToneIcon from '@mui/icons-material/AccountTreeTwoTone';
import { connect } from 'react-redux';
import { logout } from 'src/actions/user';
import { testToken } from 'src/actions/quiz';
import { useNavigate } from 'react-router-dom';
import { ConnectionOfflineContext } from 'src/contexts/ConnectionOfflineContext';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = styled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-right: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox(props) {
  const { logout, testToken, user } = props;
  const navigate = useNavigate();
  const { toggleAlert } = useContext(ConnectionOfflineContext);

  // const user = {
  //   name: 'Riza Kurniawanda',
  //   avatar: '/static/images/avatars/4.jpg',
  //   jobtitle: 'Admin'
  // };

  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
          </UserBoxText>
        </Hidden>
        <Avatar variant="rounded" alt={user.name} src={user.avatar} />
        {/* <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden> */}
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex" alignItems="center">
          <UserBoxText>
            <UserBoxLabel variant="body1">{user.name}</UserBoxLabel>
          </UserBoxText>
          <Avatar variant="rounded" alt={user.name} src={user.avatar} />
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <Box sx={{ m: 1 }}>
          <Button
            color="primary"
            fullWidth
            onClick={(e) => {
              logout({ navigate });
            }}
          >
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Sign out
          </Button>
          {/* <Button color="primary" fullWidth onClick={testToken}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Test Token
          </Button>
          <Button color="primary" fullWidth onClick={toggleAlert}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            Switch Offline
          </Button> */}
        </Box>
      </Popover>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    user: state.user.user
  };
};

export default connect(mapStateToProps, { logout, testToken })(HeaderUserbox);
