import { httpApi, httpClient, httpPublic } from './axios';
import * as API from '../api/User';
import { logout } from 'src/actions/user';

const setupInterceptor = (store) => {
  httpApi.interceptors.request.use(
    (config) => {
      const {
        user: { accessToken }
      } = store.getState();
      if (accessToken) {
        config.headers['Authorization'] = `${accessToken}`;
      }

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  httpApi.interceptors.response.use(
    (res) => {
      if (res.status === 200 || res.status === 201) {
        return { data: res.data, headers: res.headers };
      }
      return res;
    },
    async (error) => {
      const prevRequest = error?.config;
      if (error.response.status === 401) {
        // const {
        //   user: { refreshToken }
        // } = store.getState();

        // const myPrevRequest = await API.renewToken(refreshToken)
        //   .then((resp) => {
        //     store.dispatch({
        //       type: 'RENEW_TOKEN_SUCCESS',
        //       payload: resp
        //     });

        //     return httpApi.request(prevRequest);
        //   })
        //   .catch((err) => {
        //     return err;
        //   });

        // return myPrevRequest;
        window.location.href = '/logout'
      }

      return Promise.reject(error);
    }
  );

  httpPublic.interceptors.response.use(
    (res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
      return res;
    },
    (error) => {
      return Promise.reject(
        error?.response?.data?.errors !== undefined
          ? error?.response?.data?.errors[0].level +
              ' ' +
              error?.response?.data?.errors[0].objectName +
              ' ' +
              error?.response?.data?.errors[0].message
          : error?.response?.data?.message !== undefined
          ? error?.response?.data?.message
          : error?.message !== undefined
          ? error?.message
          : error
      );
    }
  );

  httpClient.interceptors.response.use(
    (res) => {
      if (res.status === 200 || res.status === 201) {
        return res.data;
      }
      return res;
    },
    (error) => {
      if (
        error.response.data.status === 400 &&
        error.response.data.message === 'Refresh token expired'
      ) {
        window.location.href = '/logout'
      }
      return Promise.reject(
        error?.response?.data?.errors !== undefined
          ? error?.response?.data?.errors[0].level +
              ' ' +
              error?.response?.data?.errors[0].objectName +
              ' ' +
              error?.response?.data?.errors[0].message
          : error?.response?.data?.message !== undefined
          ? error?.response?.data?.message
          : error?.message !== undefined
          ? error?.message
          : error
      );
    }
  );
};

export default setupInterceptor;
