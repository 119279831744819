import * as ACTION from '../../constants/fadeSnackbar';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const initialState = {
  open: false as boolean,
  openPopup: false as boolean,
  text: '' as string,
  subtext: '' as string,
  severity: '' as string
};

const fadeSnackbar = persistReducer(
  { storage, key: 'QE-fadeSnackbar' },
  (state: any = initialState, action: any) => {
    switch (action.type) {
      case ACTION.SHOW_ALERT_SUCCESS:
        return {
          ...state,
          open: true,
          text: action.payload,
          severity: 'success'
        };
      case ACTION.SHOW_ALERT_INFO:
        return {
          ...state,
          open: true,
          text: action.payload,
          severity: 'info'
        };
      case ACTION.SHOW_ALERT_WARNING:
        return {
          ...state,
          open: true,
          text: action.payload,
          severity: 'warning'
        };
      case ACTION.SHOW_ALERT_ERROR:
        return {
          ...state,
          open: true,
          text: action.payload,
          severity: 'error'
        };
      case ACTION.SHOW_POPUP:
        return {
          ...state,
          text: 'Oops . . .',
          subtext: action.payload,
          openPopup: true
        };
      case ACTION.HIDE_POPUP:
        return {
          ...state,
          openPopup: false,
          text: '',
          subtext: '',
          severity: ''
        };
      case ACTION.HIDE_ALERT:
        return {
          ...state,
          open: false,
          text: '',
          severity: ''
        };
      default:
        return state;
    }
  }
);

export default fadeSnackbar;