import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { createIntl, createIntlCache } from 'react-intl';
import enMessages from '../../i18n/messages/en.json';
import idMessages from '../../i18n/messages/id.json';

const MySwal = withReactContent(Swal);
const allMessages = {
  en: enMessages,
  id: idMessages
};

const FinishQuiz = async (myProgress) => {
  const locale =
    JSON.parse(localStorage.getItem('i18nConfig'))?.selectedLang || 'en';
  const messages = allMessages[locale];

  const cache = createIntlCache();
  const intl = createIntl(
    {
      locale,
      messages
    },
    cache
  );

  return await MySwal.fire({
    icon: 'warning',
    title: intl.formatMessage({ id: 'MODAL_FINISH_QUIZ_TITLE' }),
    text: `
      ${intl.formatMessage({
        id: 'MODAL_FINISH_QUIZ_DESCRIPTION_1'
      })} ${myProgress.totalAnswered}
      ${intl.formatMessage({
        id: 'MODAL_FINISH_QUIZ_DESCRIPTION_QUESTION'
      })}
      ${intl.formatMessage({
        id: 'MODAL_FINISH_QUIZ_DESCRIPTION_OUTOF'
      })}
      ${myProgress.totalQuestions}
      ${intl.formatMessage({
        id: 'MODAL_FINISH_QUIZ_DESCRIPTION_QUESTION'
      })}
      ${intl.formatMessage({
        id: 'MODAL_FINISH_QUIZ_DESCRIPTION_2'
      })}`,
    reverseButtons: true,
    showCancelButton: true,
    cancelButtonText: intl.formatMessage({
      id: 'MODAL_FINISH_QUIZ_CANCEL'
    }),
    confirmButtonText: intl.formatMessage({
      id: 'MODAL_FINISH_QUIZ_SUBMIT'
    })
  }).then((result) => {
    return { result };
  });
};

export default FinishQuiz;
