const LOGIN_REQUEST = 'LOGIN_REQUEST';
const LOGIN_FAILED = 'LOGIN_FAILED';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';

const RENEW_TOKEN_REQUEST = 'RENEW_TOKEN_REQUEST';
const RENEW_TOKEN_FAILED = 'RENEW_TOKEN_FAILED';
const RENEW_TOKEN_SUCCESS = 'RENEW_TOKEN_SUCCESS';

const LOGOUT = 'LOGOUT';

const UPDATE_LATEST_QUESTION_ID = 'UPDATE_LATEST_QUESTION_ID';

export {
  LOGIN_REQUEST,
  LOGIN_FAILED,
  LOGIN_SUCCESS,
  RENEW_TOKEN_REQUEST,
  RENEW_TOKEN_FAILED,
  RENEW_TOKEN_SUCCESS,
  LOGOUT,
  UPDATE_LATEST_QUESTION_ID
};
