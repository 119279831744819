import { Card, Typography, Box } from '@mui/material';
import { FormattedMessage, injectIntl } from 'react-intl';

function MiniForwardOnlyQuiz() {
  return (
    <Card
      sx={{
        mb: 2,
        width: '100%',
        backgroundColor: (theme) => theme.colors.warning.lighter,
        boxShadow: 0
      }}
    >
      <Typography
        component={'div'}
        variant="subtitle2"
        p={2}
        sx={{ fontSize: '14px' }}
      >
        <Box component={'span'} fontWeight={'bold'} color={'black'}>
          <FormattedMessage id="ALERT_FORWARD_ONLY_QUIZ_NOTHING" />
        </Box>{' '}
        <FormattedMessage id="ALERT_FORWARD_ONLY_QUIZ_TEXT" />
      </Typography>
    </Card>
  );
}

export default injectIntl(MiniForwardOnlyQuiz);
