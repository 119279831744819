import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';
import fadeSnackbar from './fadeSnackbar';
import * as user from './user';
import * as quiz from './quiz';
import * as livecode from './livecode';
import * as certificate from './certificate';

export default combineReducers({
  fadeSnackbar,
  user: user.reducer,
  quiz: quiz.reducer,
  livecode: livecode.reducer,
  certificate: certificate.reducer
});

export function* rootSaga() {
  yield all([user.saga(), quiz.saga(), livecode.saga(), certificate.saga()]);
}
