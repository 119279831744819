import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { connect } from 'react-redux';

function ResultRouter(props) {
  const { results } = props;
  let location = useLocation();

  if (results?.finished !== true) {
    return <Navigate to="/quiz" state={{ from: location }} replace />;
  }

  return <Outlet />;
}

const mapStateToProps = (state: any) => {
  return {
    results: state.quiz.results
  };
};

export default connect(mapStateToProps, {})(ResultRouter);
