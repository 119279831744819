import * as ACTION from '../constants/fadeSnackbar';

const showAlertSuccess = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION.SHOW_ALERT_SUCCESS,
    payload
  });
};

const showAlertWarning = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION.SHOW_ALERT_WARNING,
    payload
  });
};

const showAlertError = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION.SHOW_ALERT_ERROR,
    payload
  });
};

const showAlertInfo = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION.SHOW_ALERT_INFO,
    payload
  });
};

const hideAlert = () => (dispatch: any) => {
  dispatch({
    type: ACTION.HIDE_ALERT
  });
};

const showPopup = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION.SHOW_POPUP,
    payload
  });
};

const hidePopup = () => (dispatch: any) => {
  dispatch({
    type: ACTION.HIDE_POPUP
  });
};

export {
  showAlertSuccess,
  showAlertWarning,
  showAlertError,
  showAlertInfo,
  hideAlert,
  showPopup,
  hidePopup
};
