const SHOW_ALERT_SUCCESS = 'SHOW_ALERT_SUCCESS';
const SHOW_ALERT_WARNING = 'SHOW_ALERT_WARNING';
const SHOW_ALERT_ERROR = 'SHOW_ALERT_ERROR';
const SHOW_ALERT_INFO = 'SHOW_ALERT_INFO';
const SHOW_POPUP = 'SHOW_POPUP';
const HIDE_POPUP = 'HIDE_POPUP';
const HIDE_ALERT = 'HIDE_ALERT';

export {
  SHOW_ALERT_SUCCESS,
  SHOW_ALERT_WARNING,
  SHOW_ALERT_ERROR,
  SHOW_ALERT_INFO,
  HIDE_ALERT,
  SHOW_POPUP,
  HIDE_POPUP
};
