import { useContext } from 'react';

import {
  Box,
  Button,
  FormControlLabel,
  Hidden,
  IconButton,
  Switch,
  Tooltip
} from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

import HeaderMenu from './Menu';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import Logo from 'src/components/Logo';

import { setLanguage, useLang } from 'src/i18n';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 5;
        background-color: ${theme.header.background};
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: space-between;
        width: 100%;
`
);

function Header() {
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  const myLang = useLang();

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Box display="flex" alignItems="center">
        {/* <Hidden lgUp>
          <Logo />
        </Hidden> */}
        {/* <Hidden mdDown>
          <HeaderMenu />
        </Hidden> */}
      </Box>
      <Box display="flex" alignItems="center">
        {/* <HeaderButtons /> */}
        <Button
          variant={myLang === 'en' ? 'contained' : 'text'}
          onClick={() => (myLang === 'en' ? '' : setLanguage('en'))}
        >
          EN
        </Button>
        <Button
          variant={myLang === 'id' ? 'contained' : 'text'}
          onClick={() => (myLang === 'id' ? '' : setLanguage('id'))}
        >
          ID
        </Button>
        <HeaderUserbox />
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
