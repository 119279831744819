// primary 159AB1
// primary alt 1491A7
// primary alt2 48A9B9

import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

const ButtonOutlinedPrimaryDQLab = styled(Button)(
  ({ theme }) => `
       background: ${theme.palette.primary.contrastText};
       color: #159AB1;
       border: 1px solid #159AB1;
       font-weight:500; 

       &:hover {
          background: #f0f0f0;
       }
      `
);

export default ButtonOutlinedPrimaryDQLab;
