import { FC, useState, createContext } from 'react';
type ModalContext = {
  isModalOpen: any;
  modalName: string;
  closeModal: () => void;
  openModal: (value) => void;
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ModalContext = createContext<ModalContext>({} as ModalContext);

export const ModalProvider: FC = ({ children }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalName, setModalName] = useState('');
  const closeModal = () => {
    setModalOpen(false);
    setModalName('')
  };
  const openModal = (value) => {
    setModalOpen(true);
    setModalName(value)
  };

  return (
    <ModalContext.Provider
      value={{ isModalOpen, modalName, closeModal, openModal }}
    >
      {children}
    </ModalContext.Provider>
  );
};
