import * as ACTION from 'src/constants/quiz';

const startQuiz = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION.START_QUIZ_REQUEST,
    payload
  });
};

const nextBlock = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION.NEXT_BLOCK_REQUEST,
    payload
  });
};

const saveAndNextBlock = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION.SAVE_AND_NEXT_BLOCK_REQUEST,
    payload
  });
};

const prevBlock = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION.PREV_BLOCK_REQUEST,
    payload
  });
};

const currentBlock = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION.CURRENT_BLOCK_REQUEST,
    payload
  });
};

const answerQuestion = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION.ANSWER_QUESTION_REQUEST,
    payload
  });
};

const changeQuestion = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION.CHANGE_QUESTION,
    payload
  });
};

const testToken = () => (dispatch: any) => {
  dispatch({
    type: 'TEST_TOKEN_REQUEST'
  });
};

const getResult = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION.GET_RESULT_REQUEST,
    payload
  });
};

const cleanResult = () => (dispatch: any) => {
  dispatch({
    type: ACTION.CLEANUP_RESULT
  });
};

export const newNextQuestion = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION.NEW_NEXT_QUESTION_REQUEST,
    payload
  });
};

export const newSkipQuestion = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION.NEW_SKIP_QUESTION_REQUEST,
    payload
  });
};

export const newPrevQuestion = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION.NEW_PREV_QUESTION_REQUEST,
    payload
  });
};

export const newFinishQuiz = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION.NEW_FINISH_QUIZ_REQUEST,
    payload
  });
};

export const timesup = (payload: any) => (dispatch: any) => {
  dispatch({
    type: ACTION.TIMESUP_REQUEST,
    payload
  });
};

export {
  startQuiz,
  testToken,
  nextBlock,
  prevBlock,
  currentBlock,
  answerQuestion,
  changeQuestion,
  saveAndNextBlock,
  cleanResult,
  getResult
};
